/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, ChangeEvent, useCallback } from "react";
import TeamRow from "src/components/teams/TeamRow";
import useAdminContext from "src/hooks/private/useAdminContext";
import useViewport from "src/hooks/useViewport";
import FuseCoin from "src/components/icons/FuseCoin";
import TeamsToolbar from "src/components/teams/ToolBarFilter";
import useAdminRedirect from "src/hooks/private/useAdminRedirect";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import LoadingTable from "src/components/loading/LoadingTable";
import { debounce } from "lodash";

interface Team {
  coinsPurchased: number;
  coinsSubscription: number;
  companyName: string;
  createdAt: string;
  email: string;
  id: number;
  returnPhoneNumber: string;
  hasFailedPaymentsDays: boolean;
  hasFailedPayments: number;
  isDemo: boolean;
  isVacation: boolean;
  isActive: boolean;
  subscriptionID: number;
  subscriptionName: string;
  isStaff: boolean;
  appName: string;
}

interface defaultFormDataType {
  id: string;
  companyName: string;
  phoneNumber: string;
  email: string;
  subscription: string;
}

interface TeamFilters {
  search?: {
    id?: string;
    companyName?: string;
    phoneNumber?: string;
    email?: string;
  };
  filters?: {
    active?: boolean;
    demo?: boolean;
    vacation?: boolean;
    payments?: boolean;
    staff?: boolean;
    recent?: boolean;
  };
}

const Teams = () => {
  const { teams, isLoading, fetchTeams, hasMore } = useAdminContext();
  const { isMobile } = useViewport();
  const location = useLocation();

  const defaultData: defaultFormDataType = {
    id: "",
    companyName: "",
    phoneNumber: "",
    email: "",
    subscription: "-",
  };
  const [formData, setFormData] = useState<defaultFormDataType>(defaultData);

  const initialCheckedItems = {
    active: false,
    demo: false,
    vacation: false,
    payments: false,
    staff: false,
    recent: false,
  };

  // Parse the query parameters and update the initialCheckedItems
  const queryParams: any = new URLSearchParams(location.search);

  for (const [key, value] of queryParams) {
    if (key in initialCheckedItems) {
      initialCheckedItems[key as keyof typeof initialCheckedItems] =
        value === "true";
    }
  }

  const [checkedItems, setCheckedItems] = useState(initialCheckedItems);

  const debouncedSearch = useCallback(
    debounce((filters: TeamFilters) => {
      fetchTeams({ filters });
    }, 300),
    []
  );

  const handleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    const searchFilters: TeamFilters = {
      search: {
        ...(name === "id" && { id: value }),
        ...(name === "companyName" && { companyName: value }),
        ...(name === "phoneNumber" && { phoneNumber: value }),
        ...(name === "email" && { email: value }),
      },
      filters: checkedItems,
    };

    debouncedSearch(searchFilters);
  };

  // Handle checkbox filters
  useEffect(() => {
    const filters: TeamFilters = {
      filters: checkedItems,
      search: {
        id: formData.id,
        companyName: formData.companyName,
        phoneNumber: formData.phoneNumber,
        email: formData.email,
      },
    };

    fetchTeams({ filters });
  }, [checkedItems]);

  const handleLoadMore = () => {
    fetchTeams({
      append: true,
      filters: {
        search: {
          id: formData.id,
          companyName: formData.companyName,
          phoneNumber: formData.phoneNumber,
          email: formData.email,
        },
        filters: checkedItems,
      },
    });
  };

  const redirectPath = useAdminRedirect();

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  return (
    <>
      <div className="">
        <TeamsToolbar
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          numTeams={teams?.length || 0}
        />
        {isLoading && !teams ? (
          <div className="flex w-full items-center justify-center gap-2">
            <LoadingTable label={"Teams"} />
          </div>
        ) : (
          <>
            <table
              className={
                isMobile
                  ? "table-compact table w-full shadow-xl"
                  : "table w-full shadow-xl"
              }
            >
              <thead>
                <tr className="border-b-2 border-secondary text-secondary">
                  <th className="select-none rounded-tl-lg bg-card-light text-center dark:bg-card-dark">
                    ID
                  </th>
                  <th className="select-none bg-card-light text-center dark:bg-card-dark">
                    Company
                  </th>
                  <th className="select-none bg-card-light text-center dark:bg-card-dark">
                    Phone
                  </th>
                  <th className="select-none bg-card-light text-center dark:bg-card-dark">
                    Email
                  </th>
                  <th className="select-none bg-card-light text-center dark:bg-card-dark">
                    Subscription
                  </th>
                  <th className="select-none rounded-tr-lg bg-card-light text-center dark:bg-card-dark">
                    Balance
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="bg-white dark:bg-card-dark">
                    <input
                      type="text"
                      placeholder="ID"
                      name="id"
                      className="input-bordered input-secondary input input-md w-20 bg-back-light font-semibold text-text-dark placeholder:text-gray-500 dark:bg-back-dark dark:text-text-light dark:placeholder:text-gray-700"
                      onChange={handleSearch}
                      value={formData.id}
                    />
                  </td>
                  <td className="bg-white dark:bg-card-dark">
                    <input
                      type="text"
                      placeholder="Search Companies"
                      name="companyName"
                      className="input-bordered input-secondary input input-md w-full bg-back-light font-semibold text-text-dark placeholder:text-gray-500 dark:bg-back-dark dark:text-text-light dark:placeholder:text-gray-700"
                      onChange={handleSearch}
                      value={formData.companyName}
                    />
                  </td>
                  <td className="bg-white dark:bg-card-dark">
                    <input
                      type="text"
                      placeholder="Search Phones"
                      name="phoneNumber"
                      className="input-bordered input-secondary input input-md w-full bg-back-light font-semibold text-text-dark placeholder:text-gray-500 dark:bg-back-dark dark:text-text-light dark:placeholder:text-gray-700"
                      onChange={handleSearch}
                      value={formData.phoneNumber}
                    />
                  </td>
                  <td className="bg-white dark:bg-card-dark">
                    <input
                      type="text"
                      placeholder="Search Emails"
                      name="email"
                      className="input-bordered input-secondary input input-md w-full bg-back-light font-semibold text-text-dark placeholder:text-gray-500 dark:bg-back-dark dark:text-text-light dark:placeholder:text-gray-700"
                      onChange={handleSearch}
                      value={formData.email}
                    />
                  </td>
                  <td className="bg-white dark:bg-card-dark"></td>
                  <td className="bg-card-light dark:bg-card-dark">
                    <FuseCoin />
                  </td>
                </tr>
                {teams?.map((team: any, index: number) => (
                  <TeamRow team={team} index={index} key={index} />
                ))}
              </tbody>
            </table>

            {hasMore && (
              <div className="mt-4 flex justify-center">
                <button
                  onClick={handleLoadMore}
                  disabled={isLoading}
                  className="btn-secondary btn"
                >
                  {isLoading ? (
                    <>
                      <span className="loading loading-spinner"></span>
                      Loading...
                    </>
                  ) : (
                    "Load More"
                  )}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Teams;
