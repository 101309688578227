

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function getCurrentMonth() {
  const today : any = new Date();
  const currentMonth = months[today.getMonth()];
  return currentMonth;
}

function getLeapYear(year: number) {
  if (year % 4 === 0) {
    if (year % 100 === 0) {
      if (year % 400 === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

const monthEpochs = [ 2678400000, getLeapYear(new Date().getFullYear()) ? 2505600000 : 2419200000, 2678400000, 2592000000, 2678400000, 2592000000, 2678400000, 2678400000, 2592000000, 2678400000, 2592000000, 2678400000 ];

function getCount(toIterate : any, startEpoch : number, endEpoch : number){
  if (Array.isArray(toIterate)) {
    let count = 0;
    toIterate.forEach((item: any) => {
      let actionEpoch = new Date(item.createdAt).getTime();
      if (actionEpoch >= startEpoch && actionEpoch <= endEpoch) {
        count++;
      }
    });
    return count;
  }
}


function getCountQuantity(toIterate : any , startEpoch : number, endEpoch : number){
  if (Array.isArray(toIterate)) {
    let count = 0;
    toIterate.forEach((item: any) => {
      let actionEpoch = new Date(item.createdAt).getTime();
      if (actionEpoch >= startEpoch && actionEpoch <= endEpoch) {
        count = count + item.quantity;
      }
    }
    );
    return count;
  }
}

const getLastWeek = () => {
  const today : any = new Date();
  const lastWeek = [];
  // const thisDay = new Date().getDay();

  for (let i = 0; i < 6; i++){
    let newDate = new Date(today.setDate(today.getDate() + 1));
    lastWeek.push(days[newDate.getDay()])
  }
  lastWeek.pop();
  lastWeek.push("Yesterday");
  lastWeek.push("Today")
  return lastWeek;
}

function getTimeBetween(startEpoch : number, endEpoch : number) {
  const startDate = new Date(startEpoch);
  const endDate = new Date(endEpoch);
  const startMonth = months[startDate.getUTCMonth()]?.split('').slice(0, 3).join('');
  const endMonth = months[endDate.getUTCMonth()]?.split('').slice(0, 3).join('');
  const startDay = startDate.getUTCDate();
  const endDay = endDate.getUTCDate();
  return ' ' + startMonth + ' ' + startDay + ' - ' + endMonth + ' ' + endDay;
}

const getLastSixWeeks = () => {
  const today : any = new Date();
  const lastSixWeeks = [];
  const oneWeekAgo = today - (7 * 24 * 60 * 60 * 1000);
  const twoWeeksAgo = today - (14 * 24 * 60 * 60 * 1000);
  const threeWeeksAgo = today - (21 * 24 * 60 * 60 * 1000);
  const fourWeeksAgo = today - (28 * 24 * 60 * 60 * 1000);
  const fiveWeeksAgo = today - (35 * 24 * 60 * 60 * 1000);
  const sixWeeksAgo = today - (42 * 24 * 60 * 60 * 1000);
  
  lastSixWeeks.push(getTimeBetween(sixWeeksAgo, fiveWeeksAgo));
  lastSixWeeks.push(getTimeBetween(fiveWeeksAgo, fourWeeksAgo));
  lastSixWeeks.push(getTimeBetween(fourWeeksAgo, threeWeeksAgo));
  lastSixWeeks.push(getTimeBetween(threeWeeksAgo, twoWeeksAgo));
  lastSixWeeks.push(getTimeBetween(twoWeeksAgo, oneWeekAgo));
  lastSixWeeks.push("Last Week")

  return lastSixWeeks;
}

const getLastTwelveWeeks = () => {
  const today : any = new Date();
  const lastTwelveWeeks = [];
  const oneWeekAgo = today - (7 * 24 * 60 * 60 * 1000);
  const twoWeeksAgo = today - (14 * 24 * 60 * 60 * 1000);
  const threeWeeksAgo = today - (21 * 24 * 60 * 60 * 1000);
  const fourWeeksAgo = today - (28 * 24 * 60 * 60 * 1000);
  const fiveWeeksAgo = today - (35 * 24 * 60 * 60 * 1000);
  const sixWeeksAgo = today - (42 * 24 * 60 * 60 * 1000);
  const sevenWeeksAgo = today - (49 * 24 * 60 * 60 * 1000);
  const eightWeeksAgo = today - (56 * 24 * 60 * 60 * 1000);
  const nineWeeksAgo = today - (63 * 24 * 60 * 60 * 1000);
  const tenWeeksAgo = today - (70 * 24 * 60 * 60 * 1000);
  const elevenWeeksAgo = today - (77 * 24 * 60 * 60 * 1000);
  const twelveWeeksAgo = today - (84 * 24 * 60 * 60 * 1000);

  lastTwelveWeeks.push(getTimeBetween(twelveWeeksAgo, elevenWeeksAgo));
  lastTwelveWeeks.push(getTimeBetween(elevenWeeksAgo, tenWeeksAgo));
  lastTwelveWeeks.push(getTimeBetween(tenWeeksAgo, nineWeeksAgo));
  lastTwelveWeeks.push(getTimeBetween(nineWeeksAgo, eightWeeksAgo));
  lastTwelveWeeks.push(getTimeBetween(eightWeeksAgo, sevenWeeksAgo));
  lastTwelveWeeks.push(getTimeBetween(sevenWeeksAgo, sixWeeksAgo));
  lastTwelveWeeks.push(getTimeBetween(sixWeeksAgo, fiveWeeksAgo));
  lastTwelveWeeks.push(getTimeBetween(fiveWeeksAgo, fourWeeksAgo));
  lastTwelveWeeks.push(getTimeBetween(fourWeeksAgo, threeWeeksAgo));
  lastTwelveWeeks.push(getTimeBetween(threeWeeksAgo, twoWeeksAgo));
  lastTwelveWeeks.push(getTimeBetween(twoWeeksAgo, oneWeekAgo));
  lastTwelveWeeks.push("Last Week");

  return lastTwelveWeeks;
}


const getLastSixMonths = () => {
  const today : any = new Date();
  let d;
  let month;
  const lastSixMonths = [];
  for (let i = 5; i >= 0; i--) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month = months[d.getMonth()];
    lastSixMonths.push(month);
  }
  return lastSixMonths;
}

const getLastTwelveMonths = () => {
  const today : any = new Date();
  let d;
  let month;
  const lastTwelveMonths = [];
  for (let i = 11; i >= 0; i--) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month = months[d.getMonth()];
    lastTwelveMonths.push(month);
  }
  return lastTwelveMonths;
}


// counts the number of times an object is in context for a given interval which is measured in milliseconds and returns a an array of length size
function countOccurances(toIterate: any, interval: number, length: number) {
  let counts : any  = []
  const today : any = new Date();

  function getCount(startEpoch : number, endEpoch : number){
  if (Array.isArray(toIterate)) {
    let count = 0;
    toIterate.forEach((item: any) => {
      let actionEpoch = new Date(item.createdAt).getTime();
      if (actionEpoch >= startEpoch && actionEpoch <= endEpoch) {
        count++;
      }
    });
    return count;
  }
}
  for (let i = 0; i < length; i++) {
    let endEpoch = today.getTime() - (interval * i);
    let startEpoch = today.getTime() - (interval * (i + 1));
    counts.push(getCount(startEpoch, endEpoch));
  }
  return counts.reverse();
}

function countOccurancesLastWeek(toIterate: any) {
  let counts : any  = []
  let midnightEpoch = new Date().setHours(0, 0, 0, 0);

  function getCountSinceMidnight() {
    let count = 0;
    toIterate.forEach((item: any) => {
      let actionEpoch = new Date(item.createdAt).getTime();
      if (actionEpoch >= midnightEpoch) {
        count++;
      }
    });
    return count;
  }
  
  
  for (let i = 0; i < 6; i++) {
    let endEpoch = midnightEpoch - (24 * 60 * 60 * 1000 * i);
    let startEpoch = midnightEpoch - (24 * 60 * 60 * 1000 * (i + 1));
    counts.push(getCount(toIterate, startEpoch, endEpoch));
  }
  counts.reverse();
  counts.push(getCountSinceMidnight());
    return counts;
  }


// same as function above but tweaked to count the number of postcards that are scheduled to be sent between the interval.
function countSequenceOccurances(toIterate: any, interval: number, length: number) {
  let counts : any  = []
  const today : any = new Date();
  for (let i = 0; i < length; i++) {
    let endEpoch = today.getTime() - (interval * i);
    let startEpoch = today.getTime() - (interval * (i + 1));
    counts.push(getCountQuantity(toIterate, startEpoch, endEpoch));
  }
  return counts.reverse();
}

// counts the number of occrances of an object and places in array by month.
function countOccurancesMonthly(toIterate: any, length: number) {
  const today : any = new Date();
  const counts : any = [];

  let startingIndex : number = months.indexOf(getCurrentMonth()) - 1;

  let epochSinceBeginningOfMonth = today.getTime() - (today.getDate() * 86400000) - (today.getHours() * 3600000) - (today.getMinutes() * 60000) - (today.getSeconds() * 1000);
  
  counts.push(getCount(toIterate, epochSinceBeginningOfMonth, today.getTime()));
  
  for (let i = 0; i < length - 1; i++) {
    let startEpoch = epochSinceBeginningOfMonth - monthEpochs[startingIndex - 1];
    let endEpoch = epochSinceBeginningOfMonth;
      counts.push(getCount(toIterate, startEpoch, endEpoch));
      epochSinceBeginningOfMonth = epochSinceBeginningOfMonth - monthEpochs[startingIndex- 1]
    startingIndex--;
    }
  return counts.reverse();
}


function countSequenceOccurancesMonthly(toIterate: any, length: number) {
  const today : any = new Date();
  const counts : any = [];

  let startingIndex : number = months.indexOf(getCurrentMonth()) - 1;

  let epochSinceBeginningOfMonth = today.getTime() - (today.getDate() * 86400000) - (today.getHours() * 3600000) - (today.getMinutes() * 60000) - (today.getSeconds() * 1000);
  counts.push(getCountQuantity(toIterate, epochSinceBeginningOfMonth, today.getTime()));

  for (let i = 0; i < length - 1; i++) {
    let startEpoch = epochSinceBeginningOfMonth - monthEpochs[startingIndex - 1];
    let endEpoch = epochSinceBeginningOfMonth;
      counts.push(getCountQuantity(toIterate, startEpoch, endEpoch));
      epochSinceBeginningOfMonth = epochSinceBeginningOfMonth - monthEpochs[startingIndex- 1]
    startingIndex--;
    }
  return counts.reverse();
}



export { getLastWeek, getLastSixWeeks, getLastTwelveWeeks, getLastSixMonths, getLastTwelveMonths, countOccurances, countOccurancesLastWeek, countSequenceOccurances, countOccurancesMonthly, countSequenceOccurancesMonthly };