import React, {
  useCallback,
  useState,
  PropsWithChildren,
  useEffect,
} from "react";
import useAuth from "src/hooks/useAuth";
import useAlert from "src/hooks/useAlert";
import adminApi from "src/api/admin";
import { Admin } from "src/interfaces/admin";

interface TeamFilters {
  search?: {
    id?: string;
    companyName?: string;
    phoneNumber?: string;
    email?: string;
  };
  filters?: {
    active?: boolean;
    demo?: boolean;
    vacation?: boolean;
    payments?: boolean;
    staff?: boolean;
    recent?: boolean;
  };
}

interface AdminContextInterface {
  admin: Admin | null | undefined;
  fetchAdmin: () => void;
  teams: any[] | null | undefined;
  fetchTeams: (options?: { append?: boolean; filters?: TeamFilters }) => void;
  isLoading: boolean;
  hasMore: boolean;
  currentFilters: TeamFilters;
}

const AdminContextDefaults = {
  admin: null,
  fetchAdmin: () => {},
  teams: [],
  fetchTeams: () => {},
  isLoading: false,
  hasMore: true,
  currentFilters: {},
};

export const AdminContext =
  React.createContext<AdminContextInterface>(AdminContextDefaults);

interface AdminProviderProps {}

export const AdminProvider = ({
  children,
}: PropsWithChildren<AdminProviderProps>) => {
  const { currentAdmin } = useAuth();
  const adminID = currentAdmin;
  const { setAlert } = useAlert();

  const [admin, setAdmin] = useState<Admin>();
  const [teams, setTeams] = useState<any[]>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const LIMIT = 30;

  const [currentFilters, setCurrentFilters] = useState<TeamFilters>({});

  const handleError = useCallback(
    (err: any) => {
      setAlert({
        display: true,
        message: err?.message || err,
        type: "error",
      });
    },
    [setAlert]
  );

  const fetchAdmin = useCallback(async () => {
    if (adminID) {
      try {
        const admin = await adminApi.getAdmin(adminID);
        setAdmin(admin);
      } catch (err) {
        handleError(err);
      }
    }
  }, [handleError, adminID]);

  const fetchTeams = useCallback(
    async (options?: { append?: boolean; filters?: TeamFilters }) => {
      try {
        setIsLoading(true);
        const { append = false, filters } = options || {};

        if (filters) {
          setCurrentFilters(filters);
        }

        const newTeams = await adminApi.getTeams({
          limit: LIMIT,
          offset: append ? offset : 0,
          ...(filters || currentFilters),
        });

        if (newTeams.length < LIMIT) {
          setHasMore(false);
        }

        if (append) {
          setTeams((prevTeams) => [...(prevTeams || []), ...newTeams]);
          setOffset((prev) => prev + LIMIT);
        } else {
          setTeams(newTeams);
          setOffset(LIMIT);
          setHasMore(true);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [handleError, offset, currentFilters]
  );

  useEffect(() => {
    fetchAdmin();
  }, [fetchAdmin]);

  useEffect(() => {
    fetchTeams();
  }, []);

  return (
    <AdminContext.Provider
      value={{
        admin,
        fetchAdmin,
        teams,
        fetchTeams,
        isLoading,
        hasMore,
        currentFilters,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
